<template lang="pug">
.full-box.main-box(v-loading="loading")
  .customer-box
    label.blue-header 用水户信息
    .info-box
      DataInfo(
        v-if="show"
        :data="usewaterData"
        :fields="usewaterFormFields")
  .bgc-block
  .form-box
    DataformGroup(
      v-if="show"
      ref="form"
      v-model="formData"
      groupType="row"
      dot
      :colspanNum="3"
      :groupSplit="groupSplit"
      :groupTitle="groupTitle"
      :formFields="formFields")
</template>

<script>
import { mapActions } from 'vuex'
import { groupConfig, formFieldList } from '../config'
import { composePromise, renderRelationColumns } from '@/utils/common.js'

export default {
  name: 'MeterReadingDetail',
  data () {
    return {
      loading: false,
      usewaterData: {},
      usewaterFormFields: [
        {
          name: 'usewaterCode',
          label: '用水户号'
        },
        {
          name: 'customerCode',
          label: '用户编号'
        },
        {
          name: 'customerName',
          label: '用户姓名'
        },
        {
          name: 'usewaterAddress',
          label: '用水地址'
        },
        {
          name: 'usewaterPhone',
          label: '联系电话'
        },
        {
          name: 'billCycle',
          label: '缴费周期',
          relation: 'billCycle',
          render: { type: 'select' }
        },
        {
          name: 'usenatureId',
          label: '用水性质',
          relation: 'usenature',
          render: { type: 'select' }
        },
        {
          name: 'usewaterAtts',
          label: '附件',
          render: { type: 'file', showType: 'image', colSpan: 3 }
        }
      ],
      show: false,
      formData: {},
      groupSplit: groupConfig.split,
      groupTitle: groupConfig.title,
      formFields: formFieldList
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getRecordById ({ recordId }) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/meterReading/get/${recordId}`
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              resolve({ record: res.data })
            }
          })
          .catch(reject)
      })
    },
    getUserwaterById ({ record }) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/usewater/get/${record.usewaterId}`
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              const meter = res.data.meter
              const usewater = res.data
              delete usewater.meter
              resolve({ usewater, record, meter })
            }
          })
          .catch(reject)
      })
    },
    renderData ({ record, usewater, meter }) {
      this.usewaterData = usewater
      this.formData = {
        ...record,
        ...meter
      }
      this.show = true
      this.loading = false
    },
    getData () {
      const step = [
        this.renderData,
        this.getUserwaterById,
        this.getRecordById
      ]
      this.loading = true
      composePromise(step)({ recordId: this.$route.params.recordId })
        .catch(e => {
          console.error(e)
          this.loading = false
        })
    },
    renderRelation () {
      this.getRelations(['readingStatus', 'meterCondition', 'meterStatus', 'usenature', 'billCycle', 'meterType', 'diameter'])
        .then(res => {
          renderRelationColumns(res, [this.usewaterFormFields, this.formFields])
          // this.$nextTick(() => { this.show = true })
        })
    }
  },
  created () {
    this.renderRelation()
    this.getData()
  }
}
</script>

<style lang="sass" scoped>
.main-box
  display: flex
  flex-direction: row
  .customer-box
    width: 260px
    padding: 8px
    .info-box
      height: calc( 100% - 40px )
      overflow-y: auto
      padding-top: 8px
  .form-box
    width: calc( 100% - 268px )
    background-color: #fff
    overflow-y: auto
  .bgc-block
    width: 8px
    height: 100%
    background-color: #eceff0
</style>
