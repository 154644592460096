
const nameMap = new Map([
  ['meterReading', '抄表信息'],
  ['meter', '水表信息']
])

export const formFieldList = [
  {
    name: 'readingStatus',
    label: '抄表状态',
    relation: 'readingStatus',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'meterReading'
  },
  {
    name: 'lastReadingNum',
    label: '上次抄表行码',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: 'm³' },
    group: 'meterReading'
  },
  {
    name: 'estimate',
    form: { tag: 'text' },
    label: '估抄',
    render: { type: 'yesOrNo' },
    group: 'meterReading'
  },
  {
    name: 'meterCondition',
    label: '表况',
    relation: 'meterStatus',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'meterReading'
  },
  {
    name: 'thisReadingNum',
    label: '本次抄表行码',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: 'm³' },
    group: 'meterReading'
  },
  {
    name: 'quantity',
    label: '水量',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: 'm³' },
    group: 'meterReading'
  },
  {
    name: 'readingStaffName',
    label: '抄表员',
    form: { tag: 'text' },
    group: 'meterReading'
  },
  {
    name: 'readingTime',
    label: '抄表时间',
    form: { tag: 'text', colSpan: 2 },
    render: { type: 'date', timeType: 'YYYY-MM-DD HH:mm' },
    group: 'meterReading'
  },
  {
    name: 'readingAttchs',
    label: '附件',
    form: { tag: 'text', colSpan: 3 },
    render: { type: 'file', showType: 'image' },
    group: 'meterReading'
  },
  {
    name: 'meterNo',
    label: '水表编号',
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'meterType',
    label: '水表类型',
    relation: 'meterType',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'meter'
  },
  {
    name: 'meterOutNo',
    label: '水表外码',
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'diameter',
    label: '口径',
    relation: 'diameter',
    form: { tag: 'text' },
    render: { type: 'select' },
    group: 'meter'
  },
  {
    name: 'installNum',
    label: '装表行码',
    form: { tag: 'text' },
    render: { type: 'padString', suffix: 'm³' },
    group: 'meter'
  },
  {
    name: 'maxnumber',
    label: '量程',
    render: { type: 'padString', suffix: 'm³' },
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'installDate',
    label: '装表日期',
    render: { type: 'date' },
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'factory',
    label: '厂家',
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'meterSealno',
    label: '铅封号',
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'meterSelfno',
    label: '出厂编码',
    form: { tag: 'text' },
    group: 'meter'
  },
  {
    name: 'remark',
    label: '备注',
    form: { tag: 'text', colSpan: 2 },
    group: 'meter'
  },
  {
    name: 'meterAtts',
    label: '水表附件',
    form: { tag: 'text', colSpan: 3 },
    render: { type: 'file', showType: 'image' },
    group: 'meter'
  }
]

const group = formFieldList.reduce((res, field) => {
  if (!res[field.group]) res[field.group] = []
  res[field.group].push(field.name)
  return res
}, {})

export const groupConfig = Object.keys(group).reduce((res, item) => {
  res.title.push(nameMap.get(item))
  res.split.push(group[item])
  return res
}, { title: [], split: [] })
